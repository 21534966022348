import Icon from './Icon';

const CHEVRON_RIGHT = (
  <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.36644 7.14541L13.2273 12.0063L8.36644 16.8671C7.87785 17.3557 7.87785 18.145 8.36644 18.6336C8.85503 19.1221 9.6443 19.1221 10.1329 18.6336L15.8832 12.8832C16.3718 12.3946 16.3718 11.6054 15.8832 11.1168L10.1329 5.36644C9.64429 4.87785 8.85503 4.87785 8.36644 5.36644C7.89038 5.85503 7.87785 6.65682 8.36644 7.14541V7.14541Z" />
  </svg>
);

export default function IcChevronRight({className}) {
  return (
    <Icon className={className} label="ChevronRight">
      {CHEVRON_RIGHT}
    </Icon>
  );
}

IcChevronRight.propTypes = {
  className: Icon.propTypes.className,
};

IcChevronRight.defaultProps = {
  className: '',
};
